
import { createTheme } from "@material-ui/core/styles";
export const themeObject = {
    palette: {
      primary: {
        light: '#caebf2',
        main: '#062f4f',
        dark: '#565656',
        contrastText: '#fff',
      },
      secondary: {
        light: '#ff3b3f',
        main: '#813772',
        dark: '#000000',
        contrastText: '#000',
      },
      text: {
        primary: {
          light: '#caebf2',
          main: '#062f4f',
          dark: '#565656'
        },
        secondary: {
          light: '#ff3b3f',
          main: '#813772',
          dark: '#000000'
        },
      }
    },
  }

export default createTheme(themeObject);