import './Welcome.css';
import { makeStyles } from '@material-ui/core/styles';
import {Paper, Grid, Link} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingRight: '20%',
    paddingLeft: '20%',
    paddingTop: '5%',
    overflow: 'hidden',
    position: 'relative',
    height: '100vh'
  },
  container: {
    flexGrow: 1,
    textAlign: 'center'
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.primary.dark,
    backgroundColor: theme.palette.primary.dark,
    height: '6vh',
    "&:hover": {
        transform: 'scale(1.01)',
        filter: 'brightness(120%)'
      }
  }
}));




function Welcome(props) {
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
        <Grid container spacing={6} className={classes.container}>
            <Grid item xs={12} sm={6} className={classes.gridItem}>
                <Link href={'https://xwiki.checkykorpi.com'}>
                    <Paper className={classes.paper} style={{backgroundColor: 'blue', textAlign: 'center', objectFit: 'contain'}}>
                        <img src={'/xwiki-logo.png'} alt='XWiki' style={{height: '100%', width: '100%', objectFit: 'contain'}}></img>
                    </Paper>
                </Link>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.gridItem}>
                <Link href={'https://documize.checkykorpi.com'}>
                    <Paper className={classes.paper} style={{backgroundColor: '#43116E', textAlign: 'center', objectFit: 'contain'}}>
                        <img src={'/documize-512.png'} alt='XWiki' style={{height: '100%', width: '100%', objectFit: 'contain'}}></img>
                    </Paper>
                </Link>
            </Grid>
        </Grid>
        <div className='wave'></div>
  </div>

  );
}

      //
export default Welcome;

/*
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
*/